.App {
  font-family: "Jost", sans-serif;
  text-align: left;
  background-color: #071e2e;;
  min-height: calc(100vh - 56px);
  width: 100vw;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.nav-fil {
  /*color:#dc3545;*/
}

.header {
  /*background: #000;*/
  /*color: white;*/
}


.abaut-me{
  display: flex;
  position: relative;
  flex-direction: row-reverse;
}

.content {
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: space-evenly;
  position: fixed;
  top: 20%;
  left: 5%;
}

.welcome{
  background-image: url('./images/bgai.jpg');
  background-attachment: inherit;
  background-size: cover;
  min-height: calc(100vh - 56px);
  overflow: hidden;
  height: 100%;
  min-width: 1024px;
}

/*.content a {*/
/*  padding: 19px 42px;*/
/*  color: #0f14dd;*/
/*  background-color: #ffffff;*/
/*}*/

.content h4 {
  line-height: 3rem;
  letter-spacing: 1px;
}

.education {
  max-width: 90%;
  padding: 0 5%;

}

.education h1 {
  padding: 50px 10px;
  color: white;
}

.education .list-group {
  margin: 0 auto;
  width: 90%;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact h1 {
  padding: 50px;
  color: white;
}

.contact img {
  padding: 20px;
}
.contact a{
padding: 10px 0;
}

.contact .card{
  min-width: 600px;
  background: none;
  color: white;
}
.Portfolio {
    color: white;
}

.Portfolio h1 {
    padding: 50px 0;
    text-align: center;
    text-transform: uppercase;
}

.Portfolio .RowH {
    min-height: 480px;
}

.Portfolio .tab-content {
  background-color: white;
}

.Portfolio .tab-content{
  color: #0e0f20;
  min-height: 350px;
}

button.nav-link{
  font-size: 1rem;
  text-transform: uppercase;
}

button.nav-link {
  color:white;
}

button.nav-link:hover {
 color: #ffc628;
}

.web {
  display: flex;
  flex-direction: row;
}

.web img{
  padding: 5px;
  width: 48%;

}

h3 {
  padding: 30px;
}

.button {
  font-size: 14px;
  line-height: 40px;
  background-color: black;
  color: white;
  border: 2px solid white;
}

a {
  position: relative;
  line-height: 100%;
  /*background-color: #171f3b;*/
  padding: 0 40px;
  color: #bbb;
  display: block;
  text-decoration: none;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  /*font-family: roboto, sans-serif;*/
  list-style-type: none;
  transition: 0.7s;
  cursor: pointer;
  overflow: hidden;
}

a:hover span.line {
  left: 10%;
}

a:hover {
  color: rgb(250, 246, 246);
}


footer {
  background-color: #00293b;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 15px;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.App-link {
  color: #ffffff;
}

.hobby {
  max-width: 1920px;
  margin: 0 auto;
}

.hobby .item {
  height: 25vh;
}

.hobby .item {
  background-position: center;
  background-repeat: no-repeat;
}

.hobby .item:nth-of-type(1) {
  background-image: url("images/hobby1.jpg");
}

.hobby .item:nth-of-type(2) {
  background-image: url("images/hobby2.jpg");
}

.hobby .item:nth-of-type(3) {
  background-image: url("images/hobby3.jpg");
}

.hobby .item:nth-of-type(4) {
  background-image: url("images/hobby4.jpg");
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.hobby .item p {
  font-family: 'saira', sans-serif;
  color: white;
  text-align: center;
    line-height: 25vh;
  text-shadow: 0 0 4px black;
  background-color: rgba(0, 0, 0, .5);
  font-size: 20px;
  padding: 5px 0;
}

.hobby h1 {
  font-family: 'saira condensed', sans-serif;
  padding: 20px;
  text-align: center;
  font-size: 36px;
  text-transform: uppercase;
}

.hobby h4,
.hobby h3 {
  color: white;
  font-family: 'saira', sans-serif;
}

@media (min-width:533px) {
  .hobby .item {
    float: left;
    width: 50%;
    height: 40vh;
  }
}

@media (min-width:768px) {

  .hobby h1 {
    font-size: 48px;
    margin-top: 40px;
    margin-bottom: 35px;

  }

  .hobby .item p {
    font-size: 30px;
    padding: 15px 0;
  }
}

@media (min-width: 1024px) {
  .hobby .item p {
    padding: 0;
    line-height: 40vh;
    opacity: 0;
    transition: 1s;
    cursor: default;
  }

  .hobby .item:hover p {
    opacity: 1;
  }

  .hobby .item {
    background-size: 100%;
    transition: 1s;
  }

  .hobby .item:hover {
    background-size: 130%;
  }

}

@media (min-width: 1600px) {
  .hobby h1 {
    font-size: 60px;
    margin-top: 50px;
    margin-bottom: 45px;

  }

  .hobby .item p {
    font-size: 40px;
  }
}


.slogan {
  text-align: center;
  background-image: url("images/motto.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: white;
}

.slogan h1 {
  font-family: 'Saira Condensed', sans-serif;
  font-size: 26px;
  text-transform: uppercase;
  margin-bottom: 25px;

}

.slogan p {
  font-family: 'Saira', sans-serif;
  font-size: 18px;
}

.slogan p.author {
  margin-top: 20px;
  font-size: 12px;
}

.slogan .bg {
  padding: 30px 10px;
  background-color: rgba(0, 63, 114, 0.5);
}

@media (max-width: 900px) {

  .welcome {
    padding: 0 10%;
  }

  .welcome h4 {
    font-size: 1.1rem;
    line-height: 1.9rem;
    font-weight: normal;
  }

  .Portfolio .tab-content {
    min-height: 0;
  }

  .Portfolio .RowH {
    min-height: 0;
  }
  .welcome a {
    padding: 5px;
    font-size: .9rem;
  }


}

@media (max-width: 700px) {
  .contact .card{
    min-width: 200px;
  }
}
